<template>
  <div class="update-profile">
    <div class="update-profile-content">
      <div class="update-profile-left">
        <div class="profile">
          <div class="image-company">
            <img :src=" showImage ||  employeeProfile.urlProfile" alt="">
            <div class="image-button">
              <div class="btn-img">
                <i class="fas fa-camera"></i>
                <input @change="PreviewImage" class="file-input" type="file" name="resume">
              </div>
            </div>
          </div>
        </div>
        <div class="profile-form">

          <h1>Employee info</h1>
          <v-row class="profile-row">
            <v-col cols="2">
              <v-select
                  outlined
                  dense
                  label="gender"
                  :items="gender"
                  item-text="name"
                  item-value="name"
                  v-model="employeeProfile.gender"
              >

              </v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  dense
                  outlined
                  v-model="employeeProfile.name"
                  label="Employee Name"
              >

              </v-text-field>
            </v-col>
            <v-col cols="8">
              <v-text-field
                  dense
                  outlined
                  v-model="employeeProfile.surname"
                  label="Surname"
              >
              </v-text-field>
            </v-col>

            <v-col cols="8">
              <v-select
                  dense
                  outlined
                  :items="listNationality"
                  item-text="name"
                  item-value="id"
                  v-model="employeeProfile.nationality_id"
                  label="Nationality"
              >
              </v-select>
            </v-col>


            <v-col cols="8">
              <DatePicker class="date-time"
                          style="width: 100%;"
                          type="date"
                          valueType="format"
                          slot="activator"
                          :append-to-body="false"
                          placeholder="Birth Date"
                          v-model="employeeProfile.birth_date"
              ></DatePicker>
            </v-col>

            <v-col cols="8" class="mt-6">
              <v-text-field
                  class="input-number"
                  type="number"
                  dense
                  outlined
                  v-model="employeeProfile.bank_account"
                  label="Bank Account"
              >
              </v-text-field>
            </v-col>


            <v-col cols="8">
              <v-text-field
                  dense
                  outlined
                  v-model="employeeProfile.bank_name"
                  label="Bank Name"
                  @keypress="isLetter($event)"
              >
              </v-text-field>
            </v-col>

          </v-row>
        </div>
      </div>


      <div class="update-profile-right">
        <div class="profile-button-update">
          <v-btn class="btn-save-change" style="background-color: #F58A8A !important;" @click="updateProfile">
            Update Profile
          </v-btn>
        </div>
        <div class="right-profile-row">
          <div class="profile-right-form">
            <h1>Address info</h1>
            <v-row class="profile-row">
              <v-col cols="8">
                <v-select
                    dense
                    outlined
                    label="Province"
                    :items="provinces"
                    item-text="name"
                    item-value="id"
                    v-model="employeeProfile.joinAdress.province_id"
                >

                </v-select>
              </v-col>
              <v-col cols="8">
                <v-select
                    dense
                    outlined
                    label="District"
                    v-model="employeeProfile.district_id"
                    :items="districts"
                    item-text="name"
                    item-value="id"
                >
                </v-select>
              </v-col>
              <v-col cols="8">
                <v-text-field
                    dense
                    outlined
                    v-model="employeeProfile.village"
                    label="Village"
                >

                </v-text-field>
              </v-col>
            </v-row>

            <h1>Company info</h1>
            <v-row class="profile-row">
              <v-col cols="8">
                <v-text-field
                    outlined
                    dense
                    label="Employment Type"
                    v-model="employeeProfile.employment_type"
                >

                </v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: {
    DatePicker
  },
  props: {
    employeeProfile: {
      default() {
        return {province_id: null};
      }
    }
  },
  data() {
    return {
      showImage: "",
      profile: "",
      gender: [
        {
          name: "ທ້າວ"
        },
        {
          name: "ນາງ"
        }
      ],
      provinces: [],
      districts: [],
      selectedProvince: {},
      selectedDistrict: {},
      filterDistricts: [],
      listNationality: [],
    }
  },
  methods: {

    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    },

    FetchNationality() {
      this.$axios.get(`nationality`).then((res) => {
        this.listNationality = res.data.data;
        //console.log(this.listNationality)
      }).catch(() => {
      })
    },
    FetchDistrict() {
      return this.$http.get('list-location').then((res) => {
        const items = res.data;
        this.provinces = items.provinces;
        this.selectedProvince = {...this.provinces[0]};
        this.filterDistricts = items.districts;
      }).catch(() => {

      });
    },
    FilterDistricts(provinceID) {
      console.log(provinceID)
      this.districts = this.filterDistricts.filter(item => {
        if (item.province_id === provinceID) {
          return item;
        }
      });
    },
    PreviewImage(event) {
      const input = event.target;
      if (input.files && input.files[0]) {
        this.imageFile = input.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.showImage = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.UploadImage(this.imageFile);
      }
    },
    UploadImage(imageFile) {
      let formData = new FormData();
      formData.append('imageFile', imageFile)
      this.$axios.post(`upload-file`, formData).then((res) => {
        if (res.status === 200) {
          this.profile = res.data.filename
        }
      }).catch(() => {
      })
    },

    updateProfile() {
      const formData = new FormData();
      formData.append('gender', this.employeeProfile.gender);
      formData.append('name', this.employeeProfile.name);
      formData.append('surname', this.employeeProfile.surname);
      formData.append('birth_date', this.employeeProfile.birth_date);
      formData.append('nationality_id', this.employeeProfile.nationality_id);
      formData.append('bank_name', this.employeeProfile.bank_name.toUpperCase());
      formData.append('bank_account', this.employeeProfile.bank_account);
      formData.append('employment_type', this.employeeProfile.employment_type);
      formData.append('province_id', this.employeeProfile.province_id);
      formData.append('district_id', this.employeeProfile.district_id);
      formData.append('village', this.employeeProfile.village);
      formData.append('phone', this.employeeProfile.joinCompany.phone)
      if (this.imageFile) {
        formData.append('newProfile', this.profile);
      }
      // formData.append('_method', 'PUT');
      this.$axios.post(`employee/profile`, formData).then((res) => {
        if (res.data.code === 200) {
          setTimeout(() => {
            this.$emit('close');
            this.$emit('success');
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
        }
        this.btnLoading = false;
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    }

  },

  watch: {
    'employeeProfile.joinAdress.province_id': function (provinceID) {
      this.FilterDistricts(provinceID);
    }
  },
  async created() {
    console.log(this.employeeProfile)
    await this.FetchDistrict();
    this.FetchNationality();
    this.FilterDistricts(this.employeeProfile.joinAdress.province_id);
  }

}
</script>

<style scoped lang="scss">
.update-profile {
  width: 100%;
  height: auto;
  padding-bottom: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  //  background-color: red;

  .update-profile-content {
    width: 100%;
    height: auto;
    display: flex;

    .update-profile-left {
      flex: 2;
      // background-color: #719AFF;
      display: flex;
      height: 100%;
      flex-direction: column;

      .profile {
        width: 100%;
        height: 150px;
        position: relative;
        // background-color: red;
        .image-company {
          left: 250px;
          position: absolute;
          width: 150px;
          height: 150px;
          background-color: #E9EBEC;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          margin: 0 auto;
          padding: 1px;

          img {
            width: 145px;
            height: 145px;
            border-radius: 50%;
            object-fit: cover;
            overflow: hidden;
          }

          .image-button {
            width: 45px;
            height: 45px;
            background-color: #e96464;
            border-radius: 50%;
            margin: 0 auto;
            position: absolute;
            bottom: 0;
            right: 0;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            .btn-img {
              width: 45px;
              height: 45px;
              background-color: #e96464;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;

              & i {
                font-size: 22px;
                color: #FFFFFF;
                overflow: hidden;
              }
            }
          }

          .image-button :hover {
            transform: scale(1.1);
            transform-origin: bottom;
          }

        }
      }

      .profile-form {
        width: 100%;
        height: 600px;
        // background-color: yellow;
        padding: 20px 60px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 200px;


        h1 {
          font-size: 30px;
          font-family: $font-family;
          margin-top: 10px;
        }

        .profile-row {
          width: 100%;
          margin-top: 15px;

        }

      }

    }

    .update-profile-right {
      flex: 1;
      // background-color: #6CB669;

      .profile-button-update {
        width: 100%;
        height: 150px;
        // background-color: red;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        padding-right: 180px;
      }

      .right-profile-row {
        width: 100%;
        height: auto;
        // background-color: yellow;

        .profile-right-form {
          width: 100%;
          height: 600px;
          // background-color: yellow;
          padding: 20px 0;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          h1 {
            font-size: 30px;
            font-family: $font-family;
            margin-top: 10px;
          }

          .profile-row {
            width: 100%;
            margin-top: 15px;

          }
        }
      }
    }
  }


}


</style>
