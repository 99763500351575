<template>
  <div class="main-company-profile">
    <div class="view-profile">
      <div class="view-left">
        <div class="header-profile">
          <div class="header-profile-image">
            <div class="image-container">
              <div class="image-company">
                <img :src="profiles.urlProfile" alt="">
                <div class="image-button">
                  <div class="btn-img">
                    <i class="fas fa-camera"></i>
                    <input class="file-input" type="file" name="resume">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="view-right-content">
          <div class="top">
            <h1>{{ profiles.name }}</h1>
          </div>
          <div class="bottom">
            <h3>{{ profiles.surname }}</h3>
          </div>
        </div>
      </div>
      <div class="view-right">
        <v-btn class="btn-edit-profile" @click="onUpdate">
          Edit
        </v-btn>
      </div>
    </div>
    <div class="view-content">
      <div class="view-content-left">
       <div v-if="joinContact.length > 0">
         <h1>Contact Info</h1>
         <table class="table table-view">
           <tbody>
           <tr v-for="(item,idx) in joinContact" :key="idx">
             <td>
               <div class="contact-profile">
                 <div v-show="item.contact_type_name === 'Phone'" class="icon-contact-info">
                   <i class="fas fa-phone-square"></i>
                 </div>
                 <div v-show="item.contact_type_name === 'Email'" class="icon-contact-info">
                   <i class="fas fa-envelope"></i>
                 </div>
                 <div v-show="item.contact_type_name === 'Facebook'" class="icon-contact-info">
                   <i class="fab fa-facebook-square"/>
                 </div>
                 <div v-show="item.contact_type_name === 'Whatsapp'" class="icon-contact-info">
                   <i class="fab fa-whatsapp-square"/>
                 </div>
                 <div class="title-contact">
                   <div class="main-title">
                     <p class="sm-text-title">{{ item.contact_type_name }}</p>
                   </div>
                   <div class="sub-title">
                     {{ item.value }}
                   </div>
                 </div>
               </div>
             </td>
           </tr>
           </tbody>
         </table>
       </div>

        <h1>Employee Info</h1>
        <table class="table table-view">
          <tbody>
          <tr>
            <td>
              <div class="contact-profile">
                <div class="icon-contact-info">
                  <i class="far fa-user-tie"></i>
                </div>
                <div class="title-contact">
                  <div class="main-title">
                    <p class="sm-text-title">EmployeeType</p>
                  </div>
                  <div class="sub-title">
                    {{ profiles.employment_type }}
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="contact-profile">
                <div class="icon-contact-info">
                  <i class="fas fa-university"></i>
                </div>
                <div class="title-contact">
                  <div class="main-title">
                    <p class="sm-text-title">BankAccount</p>
                  </div>
                  <div class="sub-title">
                    {{ profiles.bank_account }}
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="contact-profile">
                <div class="icon-contact-info">
                  <i class="far fa-money-check-edit-alt"></i>
                </div>
                <div class="title-contact">
                  <div class="main-title">
                    <p class="sm-text-title">BankName</p>
                  </div>
                  <div class="sub-title">
                    {{ profiles.bank_name }}
                  </div>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="contact-profile">
                <div class="icon-contact-info">
                  <i class="fas fa-birthday-cake"></i>
                </div>
                <div class="title-contact">
                  <div class="main-title">
                    <p class="sm-text-title">BirthDay</p>
                  </div>
                  <div class="sub-title">
                    {{ profiles.birth_date }}
                  </div>
                </div>
              </div>
            </td>
          </tr>

          </tbody>
        </table>


        <h1>Resume Info</h1>
        <table class="table table-view">
          <tbody>
          <tr>
            <td>
              <div class="contact-profile">
                <div class="icon-contact-info">
                  <i class="far fa-user-tie"></i>
                </div>
                <div class="title-contact">
                  <div class="main-title">
                    <p class="sm-text-title">EmployeeType</p>
                  </div>
                  <div class="sub-title">
                    {{ profiles.employment_type }}
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="contact-profile">
                <div class="icon-contact-info">
                  <i class="fas fa-university"></i>
                </div>
                <div class="title-contact">
                  <div class="main-title">
                    <p class="sm-text-title">BankAccount</p>
                  </div>
                  <div class="sub-title">
                    {{ profiles.bank_account }}
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="contact-profile">
                <div class="icon-contact-info">
                  <i class="far fa-money-check-edit-alt"></i>
                </div>
                <div class="title-contact">
                  <div class="main-title">
                    <p class="sm-text-title">BankName</p>
                  </div>
                  <div class="sub-title">
                    {{ profiles.bank_name }}
                  </div>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="contact-profile">
                <div class="icon-contact-info">
                  <i class="fas fa-birthday-cake"></i>
                </div>
                <div class="title-contact">
                  <div class="main-title">
                    <p class="sm-text-title">BirthDay</p>
                  </div>
                  <div class="sub-title">
                    {{ profiles.birth_date }}
                  </div>
                </div>
              </div>
            </td>
          </tr>

          </tbody>
        </table>


      </div>


      <div class="view-content-right">
        <h1>Address Info</h1>
        <table class="table table-view">
          <tbody>
          <tr>
            <td>
              <div class="contact-profile">
                <div class="icon-contact-info">
                  <i class="fas fa-archway" style="font-size: 20px"></i>
                </div>
                <div class="title-contact">
                  <div class="main-title">
                    <p class="sm-text-title">Province</p>
                  </div>
                  <div class="sub-title">
                    {{ joinAddress.province_name }}
                  </div>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="contact-profile">
                <div class="icon-contact-info">
                  <i class="far fa-building"></i>
                </div>
                <div class="title-contact">
                  <div class="main-title">
                    <p class="sm-text-title">District</p>
                  </div>
                  <div class="sub-title">
                    {{ joinAddress.district_name }}
                  </div>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="contact-profile">
                <div class="icon-contact-info">
                  <i class="far fa-home"></i>
                </div>
                <div class="title-contact">
                  <div class="main-title">
                    <p class="sm-text-title">Village</p>
                  </div>
                  <div class="sub-title">
                    {{ joinAddress.village }}
                  </div>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <h1>Company Info</h1>
        <table class="table table-view">
          <tbody>
          <tr>
            <td>
              <div class="contact-profile">
                <div class="icon-contact-info">
                  <i class="far fa-building"></i>
                </div>
                <div class="title-contact">
                  <div class="main-title">
                    <p class="sm-text-title">Company Name</p>
                  </div>
                  <div class="sub-title">
                    {{ joinCompany.name }}
                  </div>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="contact-profile">
                <div class="icon-contact-info">
                  <i class="far fa-building"></i>
                </div>
                <div class="title-contact">
                  <div class="main-title">
                    <p class="sm-text-title">Business Type</p>
                  </div>
                  <div class="sub-title">
                    {{ joinCompany.typeBusiness }}
                  </div>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="contact-profile">
                <div class="icon-contact-info">
                  <i class="fal fa-envelope"></i>
                </div>
                <div class="title-contact">
                  <div class="main-title">
                    <p class="sm-text-title">Email</p>
                  </div>
                  <div class="sub-title">
                    {{ joinCompany.email }}
                  </div>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="contact-profile">
                <div class="icon-contact-info">
                  <i class="fal fa-phone-alt"></i>
                </div>
                <div class="title-contact">
                  <div class="main-title">
                    <p class="sm-text-title">Phone</p>
                  </div>
                  <div class="sub-title">
                    {{ joinCompany.phone }}
                  </div>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
          <Loading v-if="isLoading" />
        </table>

        <ModalEdit fullscreen title="Updated Profile">
          <template v-slot="{ close }">
            <updateEmployeeProfile :employeeProfile="profiles"  @close="close" @success="fetchProfile"/>
          </template>
        </ModalEdit>
      </div>
    </div>
  </div>
</template>

<script>
import updateEmployeeProfile from "./updateEmployeeProfile";
import Loading from "../../../components/Loading";
export default {
  components:{
    Loading,
    updateEmployeeProfile
  },
  data() {
    return {
      isLoading: false,
      profiles: {},
      joinPosition: [],
      joinContact: [],
      joinAddress: {},
      joinCompany: {}
    }
  },
  methods: {
    fetchProfile() {
      this.isLoading = true;
      this.$axios.get(`employee/profile`).then((res) => {
        this.profiles = res.data.data;
        this.joinPosition = res.data.data.joinPositions;
        this.joinContact = res.data.data.joinContact;
        this.joinAddress = res.data.data.joinAdress;
        this.joinCompany = res.data.data.joinCompany;
        this.joinResume = res.data.data.joinResumes;
        this.isLoading = false;
      }).catch(() => {
      })
    },

    onUpdate(){
      this.$store.commit("modalEdit_State", true);
    }
  },

  created() {
    this.fetchProfile();
  }
}
</script>

<style scoped lang="scss">
.main-company-profile {
  width: 100%;
  height: 100vh;

  .view-profile {
    width: 100%;
    height: 150px;
    display: flex;
    margin-top: 40px;

    .view-left {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 80px;

      .view-right-content {
        height: 150px;
        width: 100%;
        position: absolute;
        left: 230px;

        .top {
          width: 100%;
          height: 80px;
          display: flex;
          align-items: flex-end;

          h1 {
            margin-left: 15px;
            font-size: 32px;
            color: $main-color-font;
            font-family: $font-family;
            text-transform: capitalize;

          }
        }

        .bottom {
          width: 100%;
          height: 50px;
          display: flex;
          align-items: flex-start;

          h3 {
            margin-left: 15px;
            text-transform: uppercase;
          }
        }

      }
    }

    .view-right {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      position: relative;
      justify-content: flex-end;
      padding-left: 50px;
      padding-right: 100px;

      .btn-edit-profile {
        background-color: $main-btn-color-remove !important;
        color: #FFFFFF !important;
        box-shadow: 0 2px 4px 1px rgb(0 0 0 / 12%);
        border: none;
        text-transform: capitalize;
        font-family: $font-family;
        font-weight: 400 !important;
        margin-top: 5px;
        width: 80px;
        transition: ease 0.2s;
      }

      & :hover{
        transform: translateY(-10%);
      }

    }
  }

  .view-content {
    width: 100%;
    min-height: 600px;
    display: flex;

    .view-content-left {
      width: 60%;
      padding: 10px 0 100px 80px;

      h1 {
        font-family: $font-family;
        font-size: 28px;
        text-transform: capitalize;
        font-weight: 500;
        color: $main-color-font;

      }
    }

    .view-content-right {
      width: 50%;
      padding: 10px 100px;

      h1 {
        font-family: $font-family;
        font-size: 28px;
        text-transform: capitalize;
        font-weight: 500;
        color: $main-color-font;
      }
    }
  ;
  }
}

.company-passcode {
  width: 100%;
  height: 100px;
  background-color: #FAFAFA;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-weight: 600;
    font-size: 30px;
    text-transform: uppercase;
    font-family: $font-family;
    letter-spacing: 1px;
    color: $main-color-font;

  }
}
</style>
